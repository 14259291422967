.logo-text-view {
	
	position:relative;
	width:100%;

}

.logo-text-view img {
	
	position:relative;
	
	width:24px;
	height:24px;
}

.logo-text-view .text {
	
	position:absolute;
	width:90%;
	
	left:38px;
	top:0%;
	
}

/*------------------------------------------------------------------------------------------------------*/
/*                                                                                                      */
/*                                                                                                      */
/*                                           ||  MONOKAI IDE  ||                                        */
/*                                                                                                      */
/*                                                                                                      */
/*                                                                                                      */
/*------------------------------------------------------------------------------------------------------*/

.code-editor-monokai
{
	margin:12px;
}

.code-editor-monokai table {
	
	position:relative;
	width:100%;
	min-width:500px;
	
	font-family:Play;
	font-size:14px;
	
	color:#FFF;
	background-color:#404040;
	
	border-collapse:collapse;
}

	.code-editor-monokai table td
	{
		padding:1px;
		white-space: nowrap;
		width:100%;
	}

.code-editor-monokai .bar
{
	position:relative;
	
	padding-top:0.2em;
	padding-bottom:0.2em;
	
	margin-bottom:0px;
	
	font-family:Play;
	font-size:14px;
	
	color:#b20707;
	
	box-shadow:0 0 3px #404040;
	background-color:#FFF;
	
	z-index:10;
}

.code-editor-monokai .ide-container 
{
	position:relative;	
		
	overflow:scroll;
	
	box-shadow:0 0 3px #404040;
	background-color:#FFF;
	
	z-index:1;
}

	.code-editor-monokai .ide-container .keyword
	{
		font-family:Roboto;
		font-weight:600;
	
		color:#217c7e;
		
		letter-spacing:1.1px;
	}
	
	.code-editor-monokai .ide-container .string
	{
		color:rgba(255, 120, 0, 0.8);
		word-spacing:3px;
	}
	
	.code-editor-monokai .ide-container .comment
	{
		color:#669966;
	}
	
	.code-editor-monokai .ide-container .paranthesis
	{
		font-family:Play;
		letter-spacing:1.2px;
	}

.code-editor-monokai .console {
	
	font-family:Inconsolata;
	font-size:14px;
	
	overflow:scroll;
	
	border-style:solid;
	border-width:1px;
	border-color:#4e7e76;
	
	padding:6px;
	padding-top:10px;
	padding-bottom:10px;
	
	color:#FFF;
	background-color:#404040;
	
	line-height:18px;
	letter-spacing:1.4px;
	
	white-space:nowrap;
}

	.code-editor-monokai .console .red-text
	{
		color:#e60000;
	}

	.code-editor-monokai .console .blue-text
	{
		color:#4d4dff;
	}

/*------------------------------------------------------------------------------------------------------*/
/*                                                                                                      */
/*                                                                                                      */
/*                                           ||  ECLIPSE IDE  ||                                        */
/*                                                                                                      */
/*                                                                                                      */
/*                                                                                                      */
/*------------------------------------------------------------------------------------------------------*/

.code-editor-eclipse
{
	margin:12px;
}

.code-editor-eclipse table {
	
	position:relative;
	width:100%;
	min-width:500px;
	
	
	font-family:Roboto;
	font-size:16px;
	
	color:#000;
	
	border-collapse:collapse;
}
	.code-editor-eclipse table td
	{
		padding:1px;
		white-space: nowrap;
		width:100%;
	}

.code-editor-eclipse .bar
{
	position:relative;
	
	padding-top:0.2em;
	padding-bottom:0.2em;
	
	margin-bottom:0px;
	
	font-family:Play;
	font-size:14px;
	
	color:#8c8c8c;
	
	box-shadow:0 0 3px #404040;
	background-color:#FFF;
	
	z-index:10;
}

.code-editor-eclipse .ide-container
{
	position:relative;	
	
	overflow:scroll;
	
	box-shadow:0 0 3px #404040;
	background-color:#FFF;
	
	z-index:1;
}

	.code-editor-eclipse .ide-container .keyword
	{
		font-family:Roboto;
		font-weight:600;
	
		color:#217c7e;
		
		letter-spacing:1.1px;
	}
	
	.code-editor-eclipse .ide-container .string
	{
		color:rgba(255, 120, 0, 0.8);
		word-spacing:3px;
	}
	
	.code-editor-eclipse .ide-container .comment
	{
		color:#669966;
	}
	
	.code-editor-eclipse .ide-container .paranthesis
	{
		font-family:Play;
		letter-spacing:1.2px;
	}

.code-editor-eclipse .console {
	
	font-family:Inconsolata;
	font-size:14px;
	
	overflow:scroll;
	
	border-style:solid;
	border-width:1px;
	border-color:#4e7e76;
	
	padding:6px;
	padding-top:10px;
	padding-bottom:10px;
	
	line-height:18px;
	letter-spacing:1.4px;
	
	color:#000;
	background-color:#FFF;
	
	white-space:nowrap;
}

	.code-editor-eclipse .console .red-text
	{
		color:#e60000;
	}

	.code-editor-eclipse .console .blue-text
	{
		color:#4d4dff;
	}
	
/*------------------------------------------------------------------------------------------------------*/
/*                                                                                                      */
/*                                                                                                      */
/*                                           ||  QUOTATION IDE  ||                                        */
/*                                                                                                      */
/*                                                                                                      */
/*                                                                                                      */
/*------------------------------------------------------------------------------------------------------*/

.code-editor-quotation
{
	margin:12px;
}

.code-editor-quotation table {
	
	position:relative;
	width:100%;
	min-width:500px;
	
	font-family:Roboto;
	font-size:16px;
	
	color:#000;
	
	border-collapse:collapse;
}
	.code-editor-quotation table td
	{
		padding:1px;
		white-space: nowrap;
		width:100%;
	}

.code-editor-quotation .bar
{
	padding-top:0.2em;
	padding-bottom:0.2em;
	
	margin-bottom:0px;
	
	font-family:Play;
	font-size:14px;
	
	color:#b20707;
	
	border-style:solid;
	border-width:1px;
	border-color:#4e7e76;
	
	border-bottom-style:none;
}

.code-editor-quotation .ide-container{
	
	font-family:Roboto;
	font-size:16px;
	
	box-shadow:0 0 3px #404040;
	
	z-index:1;
	
	color:#000;
	
	overflow:scroll;
	
	background-color:#ffffcc;
}

	.code-editor-quotation .ide-container .keyword
	{
		font-family:Roboto;
		font-weight:600;
	
		color:#217c7e;
		
		letter-spacing:1.1px;
	}
	
	.code-editor-quotation .ide-container .string
	{
		color:rgba(255, 120, 0, 0.8);
		word-spacing:3px;
	}
	
	.code-editor-quotation .ide-container .comment
	{
		color:#669966;
	}
	
	.code-editor-quotation .ide-container .paranthesis
	{
		font-family:Play;
		letter-spacing:1.2px;
	}
	
	.code-editor-quotation .ide-container .syntax-sharp
	{
		font-family:Roboto;
		font-size:14px;
		font-weight:400;
		font-style:italic;
		letter-spacing:1px;
		color:#4d4d4d;
		
		padding:1px;
		padding-right:5px;
		padding-left:5px;
		
		box-shadow:0px 0px 1px #4d4d4d;
		
		background-color:#fff;
	}

.code-editor-quotation .console {
	
	font-family:Inconsolata;
	font-size:14px;
	
	overflow:scroll;
	
	padding:6px;
	padding-top:10px;
	padding-bottom:10px;
	
	line-height:18px;
	letter-spacing:1.4px;
	
	color:#000;
	background-color:#FFF;
	
	white-space:nowrap;
}

	.code-editor-quotation .console .red-text
	{
		color:#e60000;
	}

	.code-editor-quotation .console .blue-text
	{
		color:#4d4dff;
	}

/*------------------------------------------------------------------------------------------------------*/
/*                                                                                                      */
/*                                                                                                      */
/*                                        ||  PARAGRAPH VIEW  ||                                        */
/*                                                                                                      */
/*                                                                                                      */
/*                                                                                                      */
/*------------------------------------------------------------------------------------------------------*/

.paragraph
{
	font-family:Roboto;
	font-size:16px;
	font-weight:200;
	
	text-align:justify;
	
	padding:4px;
	margin:6px;
	
	line-height:1.45;
}

/*------------------------------------------------------------------------------------------------------*/
/*                                                                                                      */
/*                                                                                                      */
/*                                          ||  HEADER VIEW  ||                                         */
/*                                                                                                      */
/*                                                                                                      */
/*                                                                                                      */
/*------------------------------------------------------------------------------------------------------*/

.large-header
{
	font-family:Play;
	font-size:24px;
	
	text-align:justify;
	
	padding:4px;
	margin:6px;
}

.medium-header
{
	font-family:Play;
	font-size:20px;
	
	text-align:justify;
	
	padding:4px;
	margin:6px;
}

.small-header
{
	font-family:Play;
	font-size:16px;
	
	text-align:justify;
	
	padding:4px;
	margin:6px;
}

/*------------------------------------------------------------------------------------------------------*/
/*                                                                                                      */
/*                                                                                                      */
/*                                       ||  CONTAINERS VIEW  ||                                        */
/*                                                                                                      */
/*                                                                                                      */
/*                                                                                                      */
/*------------------------------------------------------------------------------------------------------*/

.container-left-border
{
	position:relative;
	width:100%;
	
	border-left-style:solid;
	border-left-width:3px;
	border-left-color:#b20707;
	
	margin-top:10px;
	margin-bottom:10px;
	padding:0px;
	padding-left:10px;
	
	display:block;
}

.container-borderless
{
	position:relative;
	width:100%;
	
	margin-top:10px;
	margin-bottom:10px;
	padding:0px;
	padding-left:10px;
	
	display:block;
}

