body
{
	margin:0px;
	
	background-color:#FFF;
}

.document-container
{
	position:relative;
	width:88%;
	
	margin:0 auto;
	
	margin-top:20px;
}

	#headerBox
	{
		position:relative;
		width:100%;
		
		font-family:Play;
		/*
		font-size:22px;
		*/
		font-weight:100;
		
		color:rgba(0, 0, 0, 0.6);
	}
	
	#definitionBox
	{
		position:relative;
		width:100%;
		
		border-left-style:solid;
		border-left-width:2px;
		border-left-color:rgba(51, 51, 204, 1);
		
		padding-top:2px;
		padding-bottom:10px;
		padding-left:10px;
		
		margin-bottom:30px;
		
		/*
		background-color:rgba(255, 255, 204, 0.6);
		*/
	}
	
		#definitionBoxHeader
		{
			position:relative;
			width:100%;
			
			font-family:Play;
			/*
			font-size:18px;
			*/
			font-weight:100;
		
			color:rgba(0, 0, 0, 0.6);
		}
		
		#definition
		{
			position:relative;
			width:100%;
			
			font-family:Play;
			/*
			font-size:16px;
			*/
			font-weight:100;
		
			color:rgba(0, 0, 0, 0.6);
			
			text-align:justify;
		}
		
	.syntaxBox
	{
		position:relative;
		width:100%;
		
		border-left-style:solid;
		border-left-width:2px;
		border-left-color:rgba(51, 51, 204, 1);
		
		font-family:DroidSansMono;
		/*
		font-size:12px;
		*/
		font-weight:100;
		
		color:rgba(0, 0, 0, 1);

		/*
		background-color:rgba(255, 255, 204, 1);
		*/
	}
	
@media screen and (max-width: 400px)
{
	.sinda-table
	{
		width:100%;
	}
}

@media screen and (min-width: 401px) and (max-width: 800px)
{
	.sinda-table
	{
		width:80%;
	}
}

@media screen and (min-width: 801px)
{
	.sinda-table
	{
		width:50%;
	}
}
	
.sinda-table
{
	position:relative;
	
	font-family:Roboto;
	
	border-collapse:collapse;
	
	min-width:100px;
	
	letter-spacing:1.1px;
	word-spacing:3.4px;
	
	color:rgba(0, 0, 0, 0.6);
	
	font-weight:400;
	
	margin:0 auto;
}	

.sinda-table tr 
{
	border-bottom-style:solid;
	border-bottom-width:1px;
	border-bottom-color:;
}

.sinda-table .odd
{
	background-color:#fff;
}

.sinda-table .even 
{
	background-color:#fff;
}

.paper-box
{	
	color:rgba(0, 0, 0, 1);
	
	background-color:rgb(255, 255, 204);
	
	display:block;
}

.paper-box-style-2
{
	color:rgba(0, 0, 0, 1);
	
	/*
	background-image:url('/image/cream_pixels.png');
	*/
	
	background-color:#f5f7f8;
	
	display:block;
}

.metal-box
{
	color:rgba(0, 0, 0, 1);
	
	display:block;
	
	background-color:rgba(245, 245, 240, 1);
}

.shadow-box
{
	box-shadow:0 0 2px rgba(0, 0, 0, 0.6);	
	
	display:block;
}

.shadow-inset-box
{
	
	box-shadow:inset 0 0 4px rgba(0, 0, 0, 0.7);	
		
	font-family:DroidSansMono;
	/*
	font-size:12px;
	*/
	font-weight:100;
	
	color:rgba(0, 0, 0, 1);
	
	display:block;
}

.border-box
{	
	border-style:solid;
	border-width:1px;/*
	border-color:rgb(102, 255, 51);*/
	border-color:#bcf2ff;
	
	font-family:DroidSansMono;
	/*
	font-size:12px;
	*/
	font-weight:100;
	
	color:rgba(0, 0, 0, 1);
	
	display:block;
}

/*
@media screen and (max-width: 400px)
{
	.wrap-box
	{
		position:relative;
		width:100%;
		
		display:block;
	}
}

@media screen and (min-width: 401px) and (max-width: 800px)
{
	.wrap-box
	{
		position:relative;
		width:40%;
		
		float:left;
		
		display:inline-block;
		
		text-align:center;
	}
}
/*
/*
@media screen and (min-width: 801px)
{
*/	

	.wrap-box
	{
		position:relative;
		/*
		float:left;
		*/
		
		display:inline-block;
	}
	
/*
}
*/

.scroll-box
{
	overflow:auto;
	white-space:nowrap;
	display:block;
}

.small-icons
{
	width:12px;
	height:12px;
}

.medium-icons
{
	width:18px;
	height:18px;
}

.large-icons
{
	width:32px;
	height:32px;
}

/****************************************************************/

.small-text
{
	font-family:Play;
	font-size:12px;

	text-align:justify;
}

.xmedium-text
{
	font-family:Play;
	font-size:14px;

	text-align:justify;
}

.medium-text
{
	font-family:Play;
	font-size:16px;
	
	text-align:justify;
}

.large-text
{
	font-family:Play;
	font-size:20px;
	
	text-align:justify;
}



.xlarge-text
{
	font-family:Play;
	font-size:24px;
	
	text-align:justify;
}

.xxlarge-text
{
	font-family:Play;
	font-size:50px;
	
	text-align:justify;
}

/****************************************************************/
.xsmall-padding
{
	padding-bottom:2px;
	padding-top:2px;
	padding-left:2px;
	padding-right:2px;
}

.small-padding
{
	padding-bottom:4px;
	padding-top:4px;
	padding-left:4px;
	padding-right:4px;
}

.medium-padding
{
	padding-bottom:6px;
	padding-top:6px;
	padding-left:6px;
	padding-right:6px;
}

.large-padding
{
	padding-bottom:8px;
	padding-top:8px;
	padding-left:8px;
	padding-right:8px;
}

.xlarge-padding
{
	padding-bottom:12px;
	padding-top:12px;
	padding-left:12px;
	padding-right:12px;
}

.xxlarge-padding
{
	padding-bottom:16px;
	padding-top:16px;
	padding-left:16px;
	padding-right:16px;
}

/****************************************************************/
.xsmall-margin
{
	margin-bottom:2px;
	margin-top:2px;
	margin-left:2px;
	margin-right:2px;
}

.small-margin
{
	margin-bottom:4px;
	margin-top:4px;
	margin-left:4px;
	margin-right:4px;
}

.medium-margin
{
	margin-bottom:6px;
	margin-top:6px;
	margin-left:6px;
	margin-right:6px;
}

.large-margin
{
	margin-bottom:8px;
	margin-top:8px;
	margin-left:8px;
	margin-right:8px;
}

.xlarge-margin
{
	margin-bottom:12px;
	margin-top:12px;
	margin-left:12px;
	margin-right:12px;
}

.xxlarge-margin
{
	margin-bottom:16px;
	margin-top:16px;
	margin-left:16px;
	margin-right:16px;
}

.code
{
	color:#000;
	font-weight:100;
}

.code-style-2
{
	font-family:Roboto;
	
	color:#FFF;
	
	font-weight:400;
	
	letter-spacing:1px;
}

.no
{
	background-color:#000;
}

hr{ 
  border: 0px; 
  height: 1px; 
  background-color:#bfbfbf; 
}

a
{
	text-decoration:none;
	color:rgba(0, 0, 0, 0.4);
}

.emphasis
{
	box-shadow:0 0 2px #808080;
}

::-webkit-scrollbar-track
{

}

::-webkit-scrollbar
{
	width: 0.3em;
	height:0.3em;	/*know why*/
}

::-webkit-scrollbar-thumb
{
	background-color: #20c5bd;
}

i{
	color:#b30000;
	font-weight:600;
}

p
{
	margin:1px;
	padding:0;
}

.syntax-word
{
	color:#4d4d4d;
	
	font-weight:300;
	
	letter-spacing:1px;
}

.linker
{
	color:#b30000;
	
	/**
	cursor:pointer;
	*/
}

.important
{
	color:#cc0000;
}

.remove-wrap
{
	white-space:nowrap;
}

/*------------------------------------------------------------------------------------------*/
@media screen and (max-width: 800px)
{
	.xxlarge-text
	{
		/*
		font-family:Roboto;
		*/
		font-size:20px;
		
		/*
		color:rgba(0, 0, 0, 0.8);
		*/
		
		text-align:justify;
	}
	
}

@media screen and (min-width: 800px) and (max-width: 1000px)
{
	.xxlarge-text
	{
		/*
		font-family:Roboto;
		*/
		font-size:24px;
		
		/*
		color:rgba(0, 0, 0, 0.8);
		*/
		
		text-align:justify;
	}

}

@media screen and (min-width: 1000px) and (max-width: 1200px)
{
	.xxlarge-text
	{
		/*
		font-family:Roboto;
		*/
		font-size:24px;
	
		/**
		color:rgba(0, 0, 0, 0.8);
		*/
		
		text-align:justify;
	}
}

@media screen and (min-width: 1200px) and (max-width: 1600px)
{
	.xxlarge-text
	{
		/*
		font-family:Roboto;
		*/
		font-size:24px;

		/**
		color:rgba(0, 0, 0, 0.8);
		*/
		
		text-align:justify;
	}
}


.block-box
{
	position:relative;
	
	display:block;
}

.sample-img
{
	position:relative;
	/*
	width:256px;
	height:256px;
	*/
	
	display:block;
	/*
	margin:auto;
	*/
}


.high-text
{
	line-height:1.45;
}