.highlighted-text-yellow {
	
	background-color:#ffff00;
	
}

.highlighted-text-green {
	
	background-color:#00ff00;
	
}

.highlighted-text-red {
	
	background-color:#ff0000;
	
}

.suppressed-text {
	
	text-decoration:line-through;
	
}

.emphasize-text
{
	color:#ffcc00;
	font-weight:600;
}

.doc-code-keyword
{
	font-family:Roboto;
	font-weight:600;

	color:#217c7e;
	
	letter-spacing:1.1px;
}

.doc-code-string
{
	color:rgba(255, 120, 0, 0.8);
	word-spacing:3px;
}

.doc-code-comment
{
	color:#669966;
}

.doc-code-paranthesis
{
	font-family:Play;
	letter-spacing:1.2px;
}

.doc-code-sharp
{
	font-family:Roboto;
	font-size:14px;
	font-weight:400;
	font-style:italic;	
	letter-spacing:1px;
	color:#4d4d4d;
	
	padding:1px;
	padding-right:5px;
	padding-left:5px;
	
	box-shadow:0px 0px 1px #4d4d4d;
	
	background-color:#fff;
}


.link-style-1								/*SUPPRESS*/
{
	font-family:Play;
	font-size:14px;
	
	color:#456;
	
	cursor:pointer;
	
	display:inline;
}

.link-style-1:hover							/*SUPPRESS*/
{
	text-decoration:underline;
}

.s-header {									/*SUPPRESS*/
	
	color:#ff6600;
	
}