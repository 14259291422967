/* LoginPage.ff.css */
.login-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
}

.login-heading {
    font-size: 24px;
    margin-bottom: 20px;
}
