.loader
{
	position:fixed;
	width:100%;
	height:100%;
	
	background-color:#FFF;
	
	z-index:200;
	
}

.loader img
{
	position:relative;
	left:48%;
	top:48%;
	
	width:32px;
	height:32px;
	
	position:center;
}
