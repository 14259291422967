body
{	
	margin: 0px ;
	height:100%;
}

.main-container
{
	position:absolute;
	width:100%;
	height:90%;
	background: white;
}

::-webkit-scrollbar-track
{
	
}

::-webkit-scrollbar
{
	width: 0em;
}

::-webkit-scrollbar-thumb
{
	background-color:#b20707;
}
	
/*------------------------------------------------------------------------------------------------------*/
/*                                                                                                      */
/*                                                                                                      */
/*                                           ||  0 - 900  ||                                            */
/*                                                                                                      */
/*                                                                                                      */
/*                                                                                                      */
/*------------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 900px)
{
	
	.up-container
	{
		position:relative;
		width:100%;
		height:30%;

		box-shadow:0 0 3px #000;	

		font-family: Play;
		font-size:100%;
		
		color:rgba(0, 0, 0, 0.6);

		background-color:#FFFFFF;
		
		z-index:99;
	}
		
		#header
		{
			position:relative;

			left:1em;
			top:30%;
			
			display:inline-block;
			
			cursor:hand;
		}
	
	.down-container
	{
		position:relative;
		width:100%;
		height:20%;
		
		
		background-color:#FFF;
	}

		.down-middle-container
		{
			position:absolute;
			width:100%;
			height:100%;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}
		
		.down-middle-top-container
		{
			position:relative;
			width:100%;
			height:15%;
		}
		
		.down-middle-badge-container
		{
			position:relative;
			width:100%;
			height:80%;
			
			overflow:hidden;
		}
		
		.down-middle-privacy-container
		{
			position:relative;
			width:100%;
			height:10%;
			
			overflow:hidden;
		}
		
		.down-middle-terms-container
		{
			position:relative;
			width:100%;
			height:10%;
			
			overflow:hidden;
		}
		
		.google-play-badge
		{
		    position:relative;
			width:auto;
			height:100%;
		    
			display: block;
            margin-left: auto;
            margin-right: auto;
	
		}
		
		.share-button-container
		{
			position:absolute;
			width:32px;
			height:100%;
			
			left:71%;
			top:0%;
		}
		
			.share-button-container img
			{
				margin-top:6px;
			}
		
	.logo
	{
		position:relative;
		width:70%;
		height:100%;
		
        display: block;
        margin-left: auto;
        margin-right: auto;
	}
	
	.loader-iframe{
	
		position:absolute;
		width:80%;
		height:100%;
		
		background-color:#FFF;
		
		z-index:50;
		
		display:none;
	}

	.loader-iframe img
	{
		position:relative;
		left:48%;
		top:48%;
		
		width:32px;
		height:32px;
		
		position:center;
	}
	
	.button-6 {
      align-items: center;
      background-color: #99ddff;
      border: 1px solid #99ddff;
      border-radius: .25rem;
      box-shadow:0 0 3px #000;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      display: inline-flex;
      font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      line-height: 1.25;
      margin-left: 0;
      min-height: 3rem;
      padding: calc(.875rem - 1px) calc(1.5rem - 1px);
      position: relative;
      text-decoration: none;
      transition: all 250ms;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      vertical-align: baseline;
      width: 100%;
    }
    
    .button-6:hover,
    .button-6:focus {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      color: rgba(0, 0, 0, 0.65);
    }
    
    .button-6:hover {
      transform: translateY(-1px);
    }
    
    .button-6:active {
      background-color: #F0F0F1;
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
      color: rgba(0, 0, 0, 0.65);
      transform: translateY(0);
    } 
      
    .button-container { 
        position: relative;
        height:100%;
    }
    
    .button-center {
      margin: 0;
      position: absolute;
      width:50%;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-family: 'Ubuntu', Helvetica, Arial, monospace;
    }
	
}

/*------------------------------------------------------------------------------------------------------*/
/*                                                                                                      */
/*                                                                                                      */
/*                                           ||  900 - 1000  ||                                         */
/*                                                                                                      */
/*                                                                                                      */
/*                                                                                                      */
/*------------------------------------------------------------------------------------------------------*/


@media screen and (min-width: 900px) and (max-width: 1000px)
{
	.up-container
	{
		position:relative;
		width:100%;
		height:30%;

		box-shadow:0 0 3px #000;	

		font-family: Play;
		font-size:100%;
		
		color:rgba(0, 0, 0, 0.6);

		background-color:#FFFFFF;
		
		z-index:99;
	}
		
		#header
		{
			position:relative;

			left:1em;
			top:30%;
			
			display:inline-block;
			
			cursor:hand;
		}
	
	.down-container
	{
		position:relative;
		width:100%;
		height:20%;

		
		background-color:#000;
	}
	
		.down-left-container
		{
			position:absolute;
			width:25%;
			height:100%;
			
			z-index:98;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}

		.down-middle-container
		{
			position:absolute;
			width:50%;
			height:100%;
			
			left:25%;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}
		
		.down-middle-top-container
		{
			position:relative;
			width:100%;
			height:10%;
		}
		
		.down-middle-badge-container
		{
			position:relative;
			width:100%;
			height:80%;
			
			overflow:hidden;
		}
		
		.down-middle-privacy-container
		{
			position:relative;
			width:100%;
			height:10%;
			
			overflow:hidden;
		}
		
		.down-middle-terms-container
		{
			position:relative;
			width:100%;
			height:10%;
			
			overflow:hidden;
		}
		
		.google-play-badge
		{
		    position:relative;
			width:auto;
			height:100%;
		    
			display: block;
            margin-left: auto;
            margin-right: auto;
	
		}
		
		.down-right-container
		{
			position:absolute;
			width:25%;
			height:100%;
			
			left:75%;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}
		
		.share-button-container
		{
			position:absolute;
			width:32px;
			height:100%;
			
			left:71%;
			top:0%;
		}
		
			.share-button-container img
			{
				margin-top:6px;
			}
		
	.logo
	{
		position:relative;
		width:70%;
		height:100%;
		
        display: block;
        margin-left: auto;
        margin-right: auto;
	}
	
	.loader-iframe{
	
		position:absolute;
		width:80%;
		height:100%;
		
		background-color:#FFF;
		
		z-index:50;
		
		display:none;
	}

	.loader-iframe img
	{
		position:relative;
		left:48%;
		top:48%;
		
		width:32px;
		height:32px;
		
		position:center;
	}
	
	.button-6 {
      align-items: center;
      background-color: #99ddff;
      border: 1px solid #99ddff;
      border-radius: .25rem;
      box-shadow:0 0 3px #000;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      display: inline-flex;
      font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      line-height: 1.25;
      margin-left: 0;
      min-height: 3rem;
      padding: calc(.875rem - 1px) calc(1.5rem - 1px);
      position: relative;
      text-decoration: none;
      transition: all 250ms;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      vertical-align: baseline;
      width: 100%;
    }
    
    .button-6:hover,
    .button-6:focus {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      color: rgba(0, 0, 0, 0.65);
    }
    
    .button-6:hover {
      transform: translateY(-1px);
    }
    
    .button-6:active {
      background-color: #F0F0F1;
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
      color: rgba(0, 0, 0, 0.65);
      transform: translateY(0);
    } 
      
    .button-container { 
        position: relative;
        height:100%;
    }
    
    .button-center {
      margin: 0;
      position: absolute;
      width:65%;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-family: 'Ubuntu', Helvetica, Arial, monospace;
    }
	
}

/*------------------------------------------------------------------------------------------------------*/
/*                                                                                                      */
/*                                                                                                      */
/*                                           ||  1000 - 1200  ||                                        */
/*                                                                                                      */
/*                                                                                                      */
/*                                                                                                      */
/*------------------------------------------------------------------------------------------------------*/

@media screen and (min-width: 1000px) and (max-width: 1200px)
{
	.up-container
	{
		position:relative;
		width:100%;
		height:30%;

		box-shadow:0 0 3px #000;	

		font-family: Play;
		font-size:100%;
		
		color:rgba(0, 0, 0, 0.6);

		background-color:#FFFFFF;
		
		z-index:99;
	}
		
		#header
		{
			position:relative;

			left:1em;
			top:30%;
			
			display:inline-block;
			
			cursor:hand;
		}
	
	.down-container
	{
		position:relative;
		width:100%;
		height:20%;
		
		
		background-color:#FFF;
	}
	
		.down-left-container
		{
			position:absolute;
			width:25%;
			height:100%;
			
			z-index:98;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}

		.down-middle-container
		{
			position:absolute;
			width:50%;
			height:100%;
			
			left:25%;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}
		
		.down-middle-top-container
		{
			position:relative;
			width:100%;
			height:10%;
		}
		
		.down-middle-badge-container
		{
			position:relative;
			width:100%;
			height:80%;
			
			overflow:hidden;
		}
		
		.down-middle-privacy-container
		{
			position:relative;
			width:100%;
			height:10%;
			
			overflow:hidden;
		}
		
		.down-middle-terms-container
		{
			position:relative;
			width:100%;
			height:10%;
			
			overflow:hidden;
		}
		
		.google-play-badge
		{
		    position:relative;
			width:auto;
			height:100%;
		    
			display: block;
            margin-left: auto;
            margin-right: auto;
	
		}
		
		.down-right-container
		{
			position:absolute;
			width:25%;
			height:100%;
			
			left:75%;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}
		
		.share-button-container
		{
			position:absolute;
			width:32px;
			height:100%;
			
			left:71%;
			top:0%;
		}
		
			.share-button-container img
			{
				margin-top:6px;
			}
		
	.logo
	{
		position:relative;
		width:70%;
		height:100%;
		
        display: block;
        margin-left: auto;
        margin-right: auto;
	}
	
	.loader-iframe{
	
		position:absolute;
		width:80%;
		height:100%;
		
		background-color:#FFF;
		
		z-index:50;
		
		display:none;
	}

	.loader-iframe img
	{
		position:relative;
		left:48%;
		top:48%;
		
		width:32px;
		height:32px;
		
		position:center;
	}
	
	.button-6 {
      align-items: center;
      background-color: #99ddff;
      border: 1px solid #99ddff;
      border-radius: .25rem;
      box-shadow:0 0 3px #000;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      display: inline-flex;
      font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      line-height: 1.25;
      margin-left: 0;
      min-height: 3rem;
      padding: calc(.875rem - 1px) calc(1.5rem - 1px);
      position: relative;
      text-decoration: none;
      transition: all 250ms;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      vertical-align: baseline;
      width: 100%;
    }
    
    .button-6:hover,
    .button-6:focus {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      color: rgba(0, 0, 0, 0.65);
    }
    
    .button-6:hover {
      transform: translateY(-1px);
    }
    
    .button-6:active {
      background-color: #F0F0F1;
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
      color: rgba(0, 0, 0, 0.65);
      transform: translateY(0);
    } 
      
    .button-container { 
        position: relative;
        height:100%;
    }
    
    .button-center {
      margin: 0;
      position: absolute;
      width:50%;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-family: 'Ubuntu', Helvetica, Arial, monospace;
    }
}

/*------------------------------------------------------------------------------------------------------*/
/*                                                                                                      */
/*                                                                                                      */
/*                                           ||  1200 - 1400  ||                                        */
/*                                                                                                      */
/*                                                                                                      */
/*                                                                                                      */
/*------------------------------------------------------------------------------------------------------*/

@media screen and (min-width: 1200px) and (max-width: 1400px)
{
	.up-container
	{
		position:relative;
		width:100%;
		height:30%;

		box-shadow:0 0 3px #000;	

		font-family: Play;
		font-size:100%;
		
		color:rgba(0, 0, 0, 0.6);

		background-color:#FFFFFF;
		
		z-index:99;
	}
		
		#header
		{
			position:relative;

			left:1em;
			top:30%;
			
			display:inline-block;
			
			cursor:hand;
		}
	
	.down-container
	{
		position:relative;
		width:100%;
		height:20%;
		
		
		background-color:#FFF;
	}
	
		.down-left-container
		{
			position:absolute;
			width:25%;
			height:100%;
			
			z-index:98;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}

		.down-middle-container
		{
			position:absolute;
			width:50%;
			height:100%;
			
			left:25%;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}
		
		.down-middle-top-container
		{
			position:relative;
			width:100%;
			height:10%;
		}
		
		.down-middle-badge-container
		{
			position:relative;
			width:100%;
			height:80%;
			
			overflow:hidden;
		}
		
		.down-middle-privacy-container
		{
			position:relative;
			width:100%;
			height:10%;
			
			overflow:hidden;
		}
		
		.down-middle-terms-container
		{
			position:relative;
			width:100%;
			height:10%;
			
			overflow:hidden;
		}
		
		.google-play-badge
		{
		    position:relative;
			width:auto;
			height:100%;
		    
			display: block;
            margin-left: auto;
            margin-right: auto;
	
		}
		
		.down-right-container
		{
			position:absolute;
			width:25%;
			height:100%;
			
			left:75%;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}
		
		.share-button-container
		{
			position:absolute;
			width:32px;
			height:100%;
			
			left:71%;
			top:0%;
		}
		
			.share-button-container img
			{
				margin-top:6px;
			}
		
	.logo
	{
		position:relative;
		width:70%;
		height:100%;
		
        display: block;
        margin-left: auto;
        margin-right: auto;
	}
	
	.loader-iframe{
	
		position:absolute;
		width:80%;
		height:100%;
		
		background-color:#FFF;
		
		z-index:50;
		
		display:none;
	}

	.loader-iframe img
	{
		position:relative;
		left:48%;
		top:48%;
		
		width:32px;
		height:32px;
		
		position:center;
	}
	
	.button-6 {
      align-items: center;
      background-color: #99ddff;
      border: 1px solid #99ddff;
      border-radius: .25rem;
      box-shadow:0 0 3px #000;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      display: inline-flex;
      font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      line-height: 1.25;
      margin-left: 0;
      min-height: 3rem;
      padding: calc(.875rem - 1px) calc(1.5rem - 1px);
      position: relative;
      text-decoration: none;
      transition: all 250ms;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      vertical-align: baseline;
      width: 100%;
    }
    
    .button-6:hover,
    .button-6:focus {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      color: rgba(0, 0, 0, 0.65);
    }
    
    .button-6:hover {
      transform: translateY(-1px);
    }
    
    .button-6:active {
      background-color: #F0F0F1;
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
      color: rgba(0, 0, 0, 0.65);
      transform: translateY(0);
    } 
      
    .button-container { 
        position: relative;
        height:100%;
    }
    
    .button-center {
      margin: 0;
      position: absolute;
      width:40%;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-family: 'Ubuntu', Helvetica, Arial, monospace;
    }
}

/*------------------------------------------------------------------------------------------------------*/
/*                                                                                                      */
/*                                                                                                      */
/*                                           ||  1400 - .... ||                                         */
/*                                                                                                      */
/*                                                                                                      */
/*                                                                                                      */
/*------------------------------------------------------------------------------------------------------*/

@media screen and (min-width: 1400px)
{
	.up-container
	{
		position:relative;
		width:100%;
		height:30%;

		box-shadow:0 0 3px #000;	

		font-family: Play;
		font-size:100%;
		
		color:rgba(0, 0, 0, 0.6);

		background-color:#FFFFFF;
		
		z-index:99;
	}
		
		#header
		{
			position:relative;

			left:1em;
			top:30%;
			
			display:inline-block;
			
			cursor:hand;
		}
	
	.down-container
	{
		position:relative;
		width:100%;
		height:20%;
		
		
		background-color:#FFF;
	}
	
		.down-left-container
		{
			position:absolute;
			width:25%;
			height:100%;
			
			z-index:98;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}

		.down-middle-container
		{
			position:absolute;
			width:50%;
			height:100%;
			
			left:25%;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}
		
		.down-middle-top-container
		{
			position:relative;
			width:100%;
			height:10%;
		}
		
		.down-middle-badge-container
		{
			position:relative;
			width:100%;
			height:80%;
			
			overflow:hidden;
		}
		
		.down-middle-privacy-container
		{
			position:relative;
			width:100%;
			height:10%;
			
			overflow:hidden;
		}
		
		.down-middle-terms-container
		{
			position:relative;
			width:100%;
			height:10%;
			
			overflow:hidden;
		}
		
		.google-play-badge
		{
		    position:relative;
			width:auto;
			height:100%;
		    
			display: block;
            margin-left: auto;
            margin-right: auto;
	
		}
		
		.down-right-container
		{
			position:absolute;
			width:25%;
			height:100%;
			
			left:75%;
			
			overflow:auto;
			overflow-x:hidden;
			
			visibility:visible;
		}
		
		.share-button-container
		{
			position:absolute;
			width:32px;
			height:100%;
			
			left:71%;
			top:0%;
		}
		
			.share-button-container img
			{
				margin-top:6px;
			}
		
	.logo
	{
		position:relative;
		width:70%;
		height:100%;
		
        display: block;
        margin-left: auto;
        margin-right: auto;
	}
	
	.loader-iframe{
	
		position:absolute;
		width:80%;
		height:100%;
		
		background-color:#FFF;
		
		z-index:50;
		
		display:none;
	}

	.loader-iframe img
	{
		position:relative;
		left:48%;
		top:48%;
		
		width:32px;
		height:32px;
		
		position:center;
	}
	
	.button-6 {
      align-items: center;
      background-color: #99ddff;
      border: 1px solid #99ddff;
      border-radius: .25rem;
      box-shadow:0 0 3px #000;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.85);
      cursor: pointer;
      display: inline-flex;
      font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 16px;
      font-weight: 600;
      justify-content: center;
      line-height: 1.25;
      margin-left: 0;
      min-height: 3rem;
      padding: calc(.875rem - 1px) calc(1.5rem - 1px);
      position: relative;
      text-decoration: none;
      transition: all 250ms;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      vertical-align: baseline;
      width: 100%;
    }
    
    .button-6:hover,
    .button-6:focus {
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
      color: rgba(0, 0, 0, 0.65);
    }
    
    .button-6:hover {
      transform: translateY(-1px);
    }
    
    .button-6:active {
      background-color: #F0F0F1;
      border-color: rgba(0, 0, 0, 0.15);
      box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
      color: rgba(0, 0, 0, 0.65);
      transform: translateY(0);
    } 
      
    .button-container { 
        position: relative;
        height:100%;
    }
    
    .button-center {
      margin: 0;
      position: absolute;
      width:30%;
      top: 50%;
      left: 50%;
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-family: 'Ubuntu', Helvetica, Arial, monospace;
    }
}