body
{
	margin:0px;
	padding:0px;
}

.menu-container
{
	position:absolute;
	width:100%;
	height:97%;
	
	top:5px;
	
	font-family:Play;
}

.menu-item
{
	position:relative;
	width:99%;
	height:24px;
	
	margin:0 auto;
	margin-bottom:2px;
	
	padding-bottom:2px;
	padding-top:2px;
	
	cursor:pointer;
	
	font-size:18px;
	color:#fff;
	
	box-shadow:0 0 1px #404040;
	
	background-color:#0099ff;
	
	z-index:100;
	
	transition:color 0.3s linear;
}

.menu-item:hover
{
	color:#000;
}

		.menu-item .logo-item
		{
			position:absolute;
			width:20px;
			height:20px;
			
			left:10px;
		}

		.menu-item .text-item
		{
			position:absolute;
			
			left:45px;
		}
		
		.menu-item a
		{
			color:#000;
		}
		
		.menu-item a:hover
		{
			color:#ff6600;
		}


.menu-item-scrollbox
{
	position:relative;
	width:100%;
	
	padding-top:2px;
	padding-bottom:2px;
	
	cursor:pointer;
	
	font-size:14px;
	
	display:none;
	
	background-color:#fff;
	
	z-index:0;
}

		.menu-item-scrollbox .menu-item
		{
			position:relative;
			width:100%;
			height:24px;
			
			margin-top:4px;
			margin-bottom:2px;
			
			cursor:pointer;
			
			font-size:16px;
			color:#000;
			
			box-shadow:initial;
			
			background:initial;
		}

				.menu-item-scrollbox .menu-item .logo-item
				{
					position:absolute;
					width:16px;
					height:16px;
					
					left:10px;
				}

				.menu-item-scrollbox .menu-item .text-item
				{
					position:absolute;
					
					left:45px;
				}
				
				.menu-item-scrollbox .menu-item-scrollbox
				{
					position:relative;
					width:100%;
					
					padding-top:2px;
					padding-bottom:2px;
					
					cursor:pointer;
					
					font-size:14px;
					
					display:none;
					
					border-style:none;
				}
				
					.menu-item-scrollbox .menu-item-scrollbox .menu-item
					{
						position:relative;
						width:100%;
						height:20px;
						
						margin-top:2px;
						margin-bottom:2px;
						
						cursor:pointer;
						
						font-size:14px;
						color:#000;
					}

						.menu-item-scrollbox .menu-item-scrollbox .menu-item .logo-item
						{
							position:absolute;
							width:14px;
							height:14px;
							
							left:10px;
						}

						.menu-item-scrollbox .menu-item-scrollbox .menu-item .text-item
						{
							position:absolute;
							
							left:45px;
						}
						
.active-page-path
{
	display:block;
}

.menu-item-scrollbox .active-page-path
{
	display:block;
}

.menu-item-scrollbox .menu-item-scrollbox .active-page-path
{
	display:block;
}