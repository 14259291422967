.example-box 
{
	border : solid 1px #b20707;
}

.information-box 
{
	background-color:#99ffff;	
}

.red-box
{
	border:solid 1px #b20707;
	background-color:#b20707;
	
	color:#fff;
}

.left-border-box /*SUPPRESS*/
{
	position:relative;
	width:100%;
	
	border-left-style:solid;
	border-left-width:2px;
	border-left-color:#b20707;
	
	display:block;
}

.left-border
{
	border-left-style:solid;
	border-left-width:2px;
	border-left-color:#b20707;
}

.color-header
{
	color:#cc0000;
}

