/**
*	This file has been SUPPRESSED
*
*/


body
{
	margin:0px;
	padding:0px;
}

.table-stylable td
{
	border-bottom-style:solid;
	border-bottom-color:#e6e6e6;
	border-bottom-width:1px;
}

.ide
{
	position:relative;

	border-collapse:collapse;

	word-spacing:3.3px;
	
	font-weight:400;
	
	table-layout:auto;
	
	box-shadow:0 0 1px #669999;
}

.syntax-ide
{
	position:relative;
	
	border-collapse:collapse;
	
	word-spacing:3.3px;
	
	font-weight:400;
	
	table-layout:auto;
}

th
{
	white-space: nowrap;
}


td
{
	padding:1px;
	white-space: nowrap;
}

.table-code
{
	width:100%;
}


.no {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
	
	color:#A8A8A8;
	
	font-weight:600;
	
	padding-right:5px;
	
	text-align:right;
	
	border-right-style:solid;
	border-right-width:1px;
	border-right-color:rgba(0, 0, 0, 0.1);
}

.ide-interactive tr:hover
{
	background-color:#e6f7ff;
}