/* HomePage.ff.css */

/* General Styles */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
}

/* Main Container */
.home-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
}

/* Titles and Headings */
.title {
    font-size: 24px;
    margin-bottom: 20px;
}

/* Buttons */
.logout-button,
.refresh-button,
.approve-button {
    font-size: 14px;
    padding: 12px 24px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    margin: 10px auto;
    display: inline-block;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.logout-button:hover,
.refresh-button:hover,
.approve-button:hover {
    background-color: #1e87e6;
    transform: scale(1.05);
}

/* ... Other button styles ... */

/* Notification Box */
.notification {
    padding: 15px;
    margin: 10px auto;
    border-radius: 5px;
}

.notification.good {
    background: limegreen;
}

.notification.bad {
    background: indianred;
}

/* Inputs, Select Box */
.input-box,
.select-box {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Filter Container */
.filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Text Styling */
.bigger-text {
    font-size: 24px;
    margin: 10px 0;
}

.middle-text {
    font-size: 14px;
    color: #888;
    margin: 10px 0;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #3498db;
    color: #fff;
}

.burger-icon {
    font-size: 24px;
    cursor: pointer;
}

/* Nav menu */
.nav-menu {
    background-color: #3498db;
    color: #fff;
    position: absolute;
    top: 70px; /* Adjust the value as needed */
    right: 20px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logout-button-icon {
    font-size: 20px;
    background: none;
    border: none;
    color: #282c34;
    cursor: pointer;
    margin-right: 20px;
    transition: color 0.3s ease;
}

.logout-button-icon:hover {
    color: #f44336; /* Red color for hover effect */
}

.close-button-icon {
    font-size: 20px;
    background: none;
    border: none;
    color: #282c34;
    cursor: pointer;
    margin-right: 20px;
    transition: color 0.3s ease;
}

.close-button-icon:hover {
    color: #f44336; /* Red color for hover effect */
}